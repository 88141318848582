import { BaseModel } from './base.model';
import { AppDate } from './date.model';
import { Attribute } from './attribute.model';
import { User } from './user.model';
import { OrderItem } from './order-item.model';
import { FilterGroup } from './filter-group.model';
import { FilterItem } from './filter-item.model';
import { CacheModel } from './cache.model';
import { CostCenter } from './cost-center.model';
import * as _ from 'lodash';

import { Product } from './product.model';
import { CUSTOM_TYPE, ECOMMERCE_TYPE, PREORDER_TYPE, BRANCH_TYPE, SUMMARY_TYPE } from '../constants/order-types';
import { Entity } from './entity.model';
import { CreditCard } from './credit-card.model';
import { PaymentMethod } from './payment-method.model';
import {SKU} from './sku.model';
import {OrderItemDelivery} from './order-item-delivery.model';
import {convertOrderState, isEmptyArray} from '../shared/utils';
import {PENDING_STATE, QUOTE_STATE} from '../constants/order-states';
import {IDecisionPointData} from '../interfaces/decision-point.data';
import {EntityLocation} from './entity-location.model';
import {IError} from './error.model';
import { Currency } from '../interfaces/currency';
import {Address} from './address.model';
import {PresetAddress} from '../interfaces/preset-address';

export class Order extends BaseModel {
    public id: number;
    public store_id: number;
    public entity_id: number;
    public entity: Entity;
    public user_id: number;
    public window_id: number;
    public type: string; // ECOMMERCE_TYPE, PREORDER_TYPE, BRANCH_TYPE, SUMMARY_TYPE
    public state: string; // QUOTE_STATE, PRODUCTION_STATE, PENDING_STATE, COMPLETE_STATE, VOID_STATE
    public created_at: AppDate;
    public updated_at: AppDate;
    public start_at: AppDate;
    public started_at: AppDate;
    public completed_at: AppDate;
    public ow_ends_at: AppDate;

    public attrs: Array<Attribute>;

    // derived properties
    public items: Array<OrderItem>;
    public payments: Array<any>; // OrderPayments
    public financials: any;
    public user: User;
    public project_name: string;
    public require_approval: boolean;
    public is_active: boolean;
    public tariff_total = 0;
    public item_count: number;

    private filters: Array<FilterGroup>;
    private registry: CacheModel;

    public costCenter: CostCenter;
    public creditCard: CreditCard;
    public currency: Currency;

    protected attr_cost_center: any;
    protected attr_credit_card: any;
    public attr_ow_label: any;
    public attr_cart_name: any;
    public attr_decision_point_id: string;
    public attr_decision_point_name: string;
    public attr_notes: string;
    public attr_planning_comments: string;
    public attr_po_num: string;
    public attr_attention: string;
    public attr_tax_exempt: any;

    public loaded: boolean; // get-active order request loads orders partially, loaded  = true when all order data is loaded

    public isWSLR = false;

    public promo_code: {code: string; description: string};

    // public addresses: Address[];  // addresses for usage in order items

    public decisionPointData: IDecisionPointData;

    public autoshipLocations: EntityLocation[];

    public error: IError;

    public attr_business_unit: string;
    public attr_company_billing_entity: string;
    public attr_vendor_number: string;
    public attr_internal_order: string;
    public attr_wbs: string;

    public attr_preset_addresses: PresetAddress[];

    public presetAddresses: Address[];

    public isNotReal: boolean;  //   this is a fake order, used when new order is not ceated yet

    constructor(data) {
        super(data, {
            num: ['id', 'store_id', 'entity_id', 'user_id', 'window_id'],
            bool: ['require_approval'],
            date: ['created_at', 'updated_at', 'start_at', 'started_at', 'completed_at', 'ow_ends_at']
        }, ['cost_center', 'credit_card', 'ow_label', 'cart_name', 'decision_point_id', 'decision_point_name', 'notes', 'po_num',
            'tax_exempt', 'attention', 'business_unit', 'company_billing_entity', 'vendor_number', 'internal_order', 'wbs', 'planning_comments', 'preset_addresses']);

        this.user = new User(this.user || {});

        if (!this.items) {
            this.items = [];
            this.item_count = 0;
        }

        this.items = this.items.map(  i => {
            const newItem = new OrderItem(i);
            newItem.order_type = this.type;
            return newItem;
        });

        if (this.id > 0 && this.items.length > 0) {
            this.item_count = this.items.length;
        }

        this.calcTotals();



        if (this.attr_cost_center) {
            this.costCenter = new CostCenter(this.attr_cost_center);
        }

        if (this.attr_credit_card) {
            this.creditCard = new CreditCard(this.attr_credit_card);
            if (!this.creditCard.id) {
                this.creditCard.id = -1;
            }
        }


        if (data && data.decisionPointData) {
            this.decisionPointData = data.decisionPointData;
        }

        // convert old  preset addresses format
        if (!isEmptyArray(this.attr_preset_addresses) && typeof this.attr_preset_addresses[0] === 'number') {
                this.attr_preset_addresses = this.attr_preset_addresses.map( i => {
                    if (typeof i === 'number') {
                        return {id: i}
                    }
                    return i;
                });
        }
        if (data && data.presetAddresses) {
            this.presetAddresses = data.presetAddresses;
        }

        if (!this.presetAddresses) {
            this.presetAddresses = [];
        }

        this.isWSLR = data?.isWSLR;

        this.isNotReal =  this.id === -1;
    }

    // public get preset_addresses(): PresetAddress[] {
    //     return this.attr_preset_addresses || [];
    // }

    public findOrderItemByProduct(product: Product): OrderItem {
        if (!product) {
            return null;
        }
        return this.items.find( i => i.product_id === product.id);
    }

    public createEmptyOrderItemForProduct(product: Product) {
        if (!product) {
            return null;
        }

        const orderItem  =  new OrderItem({
            order_id: this.id,
            order_type: this.type,
            product_id: product.id,
            product: product,
            quantity: 0,
            skus: [],
            deliveries: [],
            financials_total: {
                product_total: 0,
                subtotal: 0,
                delivery_total: 0,
                tax_total: 0
            }
        });


        product.skus.forEach((sku: SKU) => {
            orderItem.getVariationSKU(sku.id);
        });

        return orderItem;

    }

    public getItemForProduct(product: Product, options: {
        defaultDelivery?: OrderItemDelivery;
        presetAddresses?: Address[];
        userRole?: {
            canOrder: boolean;
            isEmployee: boolean;
        }} = {}): OrderItem {

        if (!product) {
            return null;
        }

        let item = this.findOrderItemByProduct(product);

        if (!item) {
            item = this.createEmptyOrderItemForProduct(product);

            if (!this.isDecisionPoint && !this.isCustom && item.deliveries.length === 0) {
                if (options.defaultDelivery) {
                    item.deliveries.push(options.defaultDelivery);
                } else {
                    if (isEmptyArray(options.presetAddresses)) {
                      item.deliveries.push(new OrderItemDelivery({
                        type: 'SHIPPING',
                        method: '',
                        quantity: 0,
                        data: {}
                      }));
                    } else {
                        if (product.hasVariations) {
                            const delivery = new OrderItemDelivery({
                                type: 'SHIPPING',
                                method: '',
                                quantity: 0,
                                data: {}
                            });
                            delivery.setAddress(options.presetAddresses[0], this.user, this.isCustom, options.userRole);
                            item.deliveries.push(delivery);
                        } else {
                            options.presetAddresses.forEach( address => {
                                const delivery = new OrderItemDelivery({
                                    type: 'SHIPPING',
                                    method: '',
                                    quantity: 0,
                                    data: {}
                                });
                                delivery.setAddress(address, this.user, this.isCustom, options.userRole);
                                item.deliveries.push(delivery);
                            });
                        }
                    }
                }
            }

        } else {
            item.product = product;
        }
        return item;
    }

    public getFilters(items: OrderItem[] = null) {
        if (this.filters != null && !items) {
            return this.filters;
        }

        const itemsToCheck = items?.length ? items : this.items;
        const tmp = {};
        const exclude = ['CHANNEL', 'HEAVY_TRADE'];
        this.registry = new CacheModel();

        _.each(itemsToCheck, (item) => {
            const itemFilters = item.getFilters();

            _.each(itemFilters, (filter) => {
                if (exclude.indexOf(filter.attr_id) === -1) {
                    if (!tmp[filter.attr_id]) {
                        tmp[filter.attr_id] = [];
                    }

                    const key = filter.attr_id + ':' + filter.id;
                    if (!this.registry.has(key)) {
                        tmp[filter.attr_id].push(filter);
                    }
                    this.registry.setItems(key, [item]);
                }
            });
        });

        let i = 0;
        const filters = [];
        _.each(tmp, (filterItems: any, group) => {
            if (group === 'BRAND') {
                const brands = {};
                const groups = [];

                _.each(filterItems, (filterItem) => {
                    const tokens = filterItem.label.split(' - ');
                    if (tokens.length === 1) {
                        tokens[1] = 'Other';
                    }
                    filterItem.label = tokens.shift();

                    const brandGroup = tokens.join(' - ');
                    if (!brands[brandGroup]) {
                        brands[brandGroup] = [];
                    }

                    brands[brandGroup].push(filterItem)
                });

                _.each(brands, (groupItems, brandGroup) => {
                    groups.push(new FilterItem({
                        id: brandGroup,
                        attr_id: brandGroup,
                        label: brandGroup,
                        selected: false,
                        children: groupItems
                    }));
                });

                filterItems = groups;
            }

            const ord = isEmptyArray(filterItems) ? 0 : (filterItems[0].ord || 0);
            const fg = new FilterGroup({id: i, label: group, items: filterItems, ord});
            filters.push(fg);
            i++;
        });

        if (items?.length) {
            this.filters = filters;
        }

        return filters;
    }

    public getFilteredItems(filters: Array<any>) {
        const items = {};

        _.each(filters, (filter) => {
            _.each(this.registry.getItems(filter), (item) => {
                items[item.id] = item;
            });
        });

        return _.values(items);
    }

    public getBudgetApplied() {
        return this.items.reduce((total, item) => {
            return total + item.financials_total.budget_total;
        }, 0);
    }

    public get selectLabel() {
        if (!this.id) {
            return '';
        }

        let label = '(Unnamed Order)';
        const items = ' - ' + this.item_count + ' Items';

        if (this.type === 'BRANCH_TYPE') {
            return '#' + this.id + ' (WSLR # ' + this.user.external_id + ' - ' + this.user.fullName() + ')' + items;
        }
        if (this.type === ECOMMERCE_TYPE) {
            label = '(Unnamed Shopping Cart)';
        }

        return '#' + this.id + ' ' + ((this.attr_cart_name) ? '(' + this.attr_cart_name + ')' : label) + items;
    }

    get cartName() {
        return this.attr_cart_name || 'Unnamed Order';
    }


    hasCostCenter() {
        return this.costCenter && this.costCenter.id > 0;
    }

    hasCreditCard() {
        return this.creditCard && this.creditCard.isValid;
    }

    setPaymentMethod(payment: PaymentMethod) {
        if (!payment) {
            return;
        }

        if (payment.isAccount()) {
            this.costCenter = payment as CostCenter;
            this.creditCard = null;
        } else {
            this.creditCard = payment as CreditCard;
            this.costCenter = null;
        }
    }

    get isPaymentMethodDefined(): boolean {
        if (this.isWSLR) {
            return true;
        }
        if (this.type === ECOMMERCE_TYPE) {
            return this.hasCostCenter() || this.hasCreditCard();
        } else {
            return this.hasCostCenter();
        }
    }

    get taxExempt(): boolean {

        if (this.attr_tax_exempt) {
            if (typeof this.attr_tax_exempt === 'boolean') {
                return this.attr_tax_exempt;
            }
            return this.attr_tax_exempt === 'true';
        }
        return false;
    }

    set taxExempt(b: boolean)  {
        this.attr_tax_exempt = b;
        if (this.items) {
            this.items.forEach( i => i.is_tax_exempt = b);
        }
    }

    // show taxes if defined and not taxExempt, otherwise 0
    get taxes(): number {
        return this.financials.tax_total ;
    }

    get couponAmount(): number {
        return this.financials.coupon_total;
    }

    get discountTotal(): number {
        return this.financials.discount_total;
    }
    get totalCost(): number {
        return this.financials.grand_total;
    }

    get subTotal(): number {
        return this.financials.subtotal;
    }

    get budgetTotal(): number {
        return this.financials.budget_total;
    }

    get shippingTotal(): number {
        return this.financials.delivery_total;
    }

    get promoCodeExists(): boolean {
        return !!this.promo_code;
    }


    private initialFinancials() {
        return {
            budget_total: 0,
            coupon_total: 0,
            discount_total: 0,
            tax_total: 0,
            grand_total : 0,
            subtotal : 0,
            delivery_total: 0,
        };
    }

    calcTotals() {

        if (this.items.length > 0) {
            // when order is loaded
            this.financials = this.initialFinancials();
            this.items.forEach( item => {

                this.financials.grand_total += item.totalCost;
                // tariff_total can be undefined
                this.financials.subtotal += item.subTotal;
                this.financials.tax_total += item.taxes;
                this.financials.coupon_total += item.couponDiscount;
                this.financials.discount_total += item.totalDiscount; // coupon + discount
                this.financials.delivery_total += item.shippingTotal;
                this.financials.budget_total += item.budget;
                this.tariff_total += item.tariff_total;

            });
        } else if (this.item_count > 0) {
            // order is not loaded yet
            if (!this.financials) {
                this.financials = this.initialFinancials();
            }
        } else {
            this.financials = this.initialFinancials();
        }

    }


    public updateOrderItems(orderItem: OrderItem | number) {
        if (!orderItem) {
            return;
        }

        if ( typeof  orderItem === 'number') {
            // remove order from list
            this.items =  this.items.filter( i => i.id !== orderItem);
        } else {
            const idx = this.items.findIndex(i => i.id === orderItem.id);
            if (idx !== -1) {
                // update order in list
                this.items = Object.assign([...this.items], {[idx]: orderItem});
            } else {
                // add order to list
                this.items = [...this.items, orderItem];
            }

            // // set addresses to order item if not defined
            // if (isEmptyArray(orderItem.allAddresses) && !isEmptyArray(this.addresses)) {
            //     orderItem.allAddresses = [...this.addresses];
            //     orderItem.computeAvailableAddresses(this.isWSLR);
            // }
        }


        this.item_count = this.items.length;

        this.calcTotals();
    }

    public get isDecisionPoint(): boolean {
        return !!this.attr_decision_point_id;
    }

    public get supportsCustomization(): boolean {
        return (this.isOnDemand || this.isByingWindow) && this.isDecisionPoint !== true;
    }

    // _.cloneDeep(this.order.items) should not be used as it doesn't copy dynamic attributes
    public cloneOrderItems(): OrderItem[] {
        return this.items.map( (i: OrderItem) => new OrderItem(i));
    }


    public get isOnDemand(): boolean {
        return this.type === ECOMMERCE_TYPE;
    }

    public get isByingWindow(): boolean {
        return this.type === PREORDER_TYPE;
    }

    public get isCustom(): boolean {
        return this.type === CUSTOM_TYPE;
    }

    public get isBranch(): boolean {
        return this.type === BRANCH_TYPE;
    }

    public get isSummary(): boolean {
        return this.type === SUMMARY_TYPE;
    }

    public get orderLabel(): string {
        return this.isOnDemand ? 'Cart' : 'Order';
    }


    public productExist(product: Product) {
        return !!this.items.find( i => {
            return i.product_id === product.id
        })
    }

    public findOrderItem(orderItemId: number) {
        return this.items.find( i => i.id === orderItemId);
    }


    public createOrderItem(data): OrderItem {
        const orderItem = new OrderItem(data);
        orderItem.order_type = this.type;
        return orderItem;
    }

    public get paymentMethod(): string  {
        return this.creditCard ? 'Credit Card' : 'Terms';
    }

    public get stateDescription(): string {
        return convertOrderState(this.state);
    }

    public get canEdit(): boolean {
        return this.state === QUOTE_STATE;
    }

    public get totalShipments(): number {
        return this.items.reduce( (total, i) => (total + i.getActualDeliveries().length), 0);
    }

    public get orderUrl(): string {
        return this.canEdit ? `/cart/${this.id}` : `/account/orders/${this.id}`;
    }

    public equal(order: Order): boolean {
        if (!order) {
            return false;
        }
        // compare by id
        if (this.id !== order.id) {
            return false;
        }

        // compare by type
        if (this.type !== order.type) {
            return false;
        }

        // compare by WSLR
        if (this.isWSLR !== order.isWSLR) {
            return false;
        }

        // compare by DP
        if ( (this.isDecisionPoint !== order.isDecisionPoint) || (this.decisionPointData !== order.decisionPointData) ) {
            return false;
        }

        return true;

    }

    public get promoCodeDescription(): string {
        if (!this.promo_code) {
            return null;
        }

        return this.promo_code.description;
    }

    isTheSame(order: Order) {
        if (!order) {
            return false;
        }

        if (this.id !== order.id) {
            return false;
        }

        if (this.entity_id !== order.entity_id) {
            return false;
        }

        if (this.attr_decision_point_id !== order.attr_decision_point_id) {
            return false;
        }

        return true;
    }

    get limited(): boolean {
        return !this.id;
    }

    containsProduct(product: Product): boolean {
        if (!product) {
            return false;
        }

        if (isEmptyArray(this.items)) {
            return false;
        }

        return this.items.filter( i => i.product_id === product.id ).length > 0;
    }


    get hasRestrictedItems(): boolean {
        if (isEmptyArray(this.items)) {
            return false;
        }
        return this.items.filter( i => i.restricted).length > 0;
    }

    get internalOrder(): string {
        return this.wbsCode || this.internalOrderCode || '';
    }


    get wbsCode(): string | undefined {
        return this.isWBSDefined ? this.attr_wbs : '';
    }

    get internalOrderCode(): string | undefined {
        return this.isInternalOrderDefined ? this.attr_internal_order : '';
    }

    get isWBSDefined(): boolean {
        return !!this.attr_wbs && this.attr_wbs !== '0';
    }

    get isInternalOrderDefined(): boolean {
        return !!this.attr_internal_order && this.attr_internal_order !== '0';
    }

    public get isRequiredApproval(): boolean {
        return this.state === PENDING_STATE;
    }


    public findUniqueShipmentAddresses(): Address[] {
        if (isEmptyArray(this.items)) {
            return [];
        }

        const addresses: Address[] = [];
        this.items.forEach( i => {
            i.deliveries.forEach( d => {
                if (d.quantity > 0) {
                    const deliveryAddress = d.getAddress();
                        if (addresses.findIndex( a => a.uniqueAddressId === deliveryAddress.uniqueAddressId) === -1) {
                        addresses.push(deliveryAddress);
                    }
                }
            });
        });

        return addresses;
    }


    // return  delivery addresses  and  preset addresses
    public findAllOrderAddresses(): Address[] {

        const addresses = !isEmptyArray(this.presetAddresses) ? [...this.presetAddresses] : [];
        const shippingAddresses  =  this.findUniqueShipmentAddresses();
        shippingAddresses.forEach( sh => {
            if (addresses.findIndex( ad => ad.uniqueAddressId === sh.uniqueAddressId) === -1) {
                addresses.push(sh);
            }
        });

        return addresses;
    }

    public hasShipmentsContainAddress(address: Address): boolean {
        if (!address) {
            return false;
        }
        return this.findUniqueShipmentAddresses().some( a => a.uniqueAddressId ===  address.uniqueAddressId);
    }

    public hasPresetsContainAddress(address: Address): boolean {
        if (isEmptyArray(this.presetAddresses) || !address) {
            return false;
        }
        return this.presetAddresses.some(a => a.uniqueAddressId ===  address.uniqueAddressId);
    }

    public hasShipmentByAddress(address: Address): boolean {
        return this.items.some( i => {
            return i.containsDeliveryByAddress(address);
        });
    }


    public get orderCurrency(): Currency  {
        if (this.currency) {
            return this.currency;
        }

        if (!isEmptyArray(this.items)) {
            return this.items[0].product?.currency;
        }

        return null;
    }
}
